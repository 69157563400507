import { useMemo } from 'react'
import { createStore, applyMiddleware } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'

export let store: any

const initialState = {
  deviceWidth: -1,  // Undefined but can be compared against easily
  networkIssues: false,
  didLoadUserOptions: false,
  userOptions: {},
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'NETWORK_ISSUES':
      return {
        ...state,
        networkIssues: action.state,
      }
    case 'USER_OPTIONS':
      const options = {
          ...state.userOptions,
          ...action.state,
      }
      if (typeof window !== 'undefined')
        window.localStorage.userOptions = JSON.stringify(options);
      return {
        ...state,
        didLoadUserOptions: true,
        userOptions: options
      }
    case 'DEVICE_WIDTH':
      return {
        ...state,
        deviceWidth: action.deviceWidth,
      }
    default:
      return state
  }
}

function initStore(preloadedState = initialState) {
  return createStore(
    reducer,
    preloadedState,
    //composeWithDevTools(applyMiddleware())
    applyMiddleware()
  )
}

export const initializeStore = (preloadedState: any) => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore(initialState: any) {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  return store
}
