import getConfig from 'next/config'

const { publicRuntimeConfig: publicConfig } = getConfig();

const config = {
  site: {
    apiRoot: (publicConfig.API_ROOT || "http://localhost:6542/") + 'api/symmetry/v1/query',
    name: publicConfig.SITE_NAME || 'Second Heaven',
    tagline: publicConfig.SITE_TAGLINE || 'Man I hate Javascript',
    branch: publicConfig.SITE_ENV || 'local',
    navbarColor: publicConfig.SITE_COLOR || 'blue',
    icon: publicConfig.SITE_ICON || undefined,
    auth: {
      type: 'oauth2', // or local
      name: publicConfig.SITE_NAME || 'Local Machogang',
      callbackDomain: null, // auto-discover
      url: (publicConfig.API_ROOT || "http://localhost:6542/") + 'aegis/oauth2/authorize',
      clientId: publicConfig.CLIENT_ID || 'CuzM6VybV3hMcHMWggwfPEnsSCztIeCVqPz217o-yCo',
      scopes: 'MACHINE_ACCESS RIVAL_WRITE SETTINGS_READ SETTINGS_WRITE',
    },
  },
  sentry: {
    dsn: publicConfig.SENTRY_DSN || false,
    release: publicConfig.BUILD_VERSION || undefined,
    environment: publicConfig.ENV || undefined,
  },
};

export default config;
