import withApollo from 'next-with-apollo';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

import cookies from 'next-cookies';

import config from '@/config';
import { redirLogin } from '@/lib/utils';
import { store } from '@/lib/store';

import introspectionQueryResultData from '@/fragmentTypes.json';

import { MediaContextProvider } from '@/lib/media';


export default withApollo(
  ({ ctx, initialState }) => {
    const unauthorizedErrorHandler = onError(({ networkError }) => {
      // We only want this to trigger on the client side, so we'll try to pull
      // some magic here
      if ((networkError as any)?.statusCode == 401 && typeof window !== "undefined") {
        redirLogin(true);
      } else if (!!networkError) {
        store?.dispatch({
          type: "NETWORK_ISSUES",
          state: true,
        })
      } else {
        store?.dispatch({
          type: "NETWORK_ISSUES",
          state: false,
        })
      }

    })

    const retryLink = new RetryLink({
      attempts: {
        // Skip the retry link if the error code is 401; we don't want to hang
        // on this due to SSR delaying the page thanks to retries
        retryIf: (error, _) => !!error && error?.statusCode != 401,
      }
     });

    const accessToken = cookies({req: ctx?.req}).accessToken;
    const httpLink = new HttpLink({
      uri: config.site.apiRoot,
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : ''
      },
    });

    const possibleTypes: any = {};
    introspectionQueryResultData.__schema.types.forEach(supertype => {
      if (supertype.possibleTypes) {
        possibleTypes[supertype.name] =
          supertype.possibleTypes.map(subtype => subtype.name);
      }
    });

    let cache = new InMemoryCache({
      possibleTypes,
      typePolicies: {
        // "Namespaces"
        BBCRoot: { merge: true },
        BSTRoot: { merge: true },
        IIDXRoot: { merge: true },
        JubeatRoot: { merge: true },
        SDVXRoot: { merge: true },
        QMARoot: { merge: true },

        // Other shit like configuration data
        ApiDetails: { merge: false },
        Game: { merge: false },
        Series: { merge: false },

        AnalyticsItem: { merge: false },
        IncomeAnalytricsData: { merge: false },
        PlayAnalyticsData: { merge: false },
        IIDXMedalState: { merge: false },
        IIDXMedalStats: { merge: false },
        IIDXPlayStatistics: { merge: false },
      }
    }).restore(initialState || {});

    return new ApolloClient({
      link: from([retryLink, unauthorizedErrorHandler, httpLink]),
      connectToDevTools: !config.sentry.dsn,
      resolvers: {
        Query: {
          isLoggedIn() {
            let accessToken = cookies({req: ctx?.req}).accessToken;
            return !!accessToken && accessToken != "";
          },
        }
      },
      cache
    });
  },
  {
    render: ({ Page, props }) => {
      return (
        <MediaContextProvider>
          <ApolloProvider client={props.apollo}>
            <Page {...props} />
          </ApolloProvider>
        </MediaContextProvider>
      );
    }
  }
);
