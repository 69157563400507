import Router, { useRouter } from 'next/router'
import { useSelector, shallowEqual } from 'react-redux'

import queryString from 'query-string';

import config from '@/config';
import { store } from '@/lib/store';

export const getProfileId = () => {
  const router = useRouter()
  const { pid: argPid } = router.query

  let pid: string = (argPid instanceof Array) ? argPid[0] : argPid;

  if (pid == 'me') {
    return null;
  } else {
    return pid;
  }
}

export const playerIdToText = (id: number) => {
  return ("" + id).padStart(8, "0").slice(0, 4) + "-" + ("" + id).padStart(8, "0").slice(4, 8);
}

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export const thousandPlaceSeparator = (num: number, sep: string = ",") => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
}

export function filterUnique<T>(value: T, index: number, self: Array<T>) {
  return self.indexOf(value) == index;
};

export const sortByField = (field: string, desc: boolean, defaultValue: any) => {
  let resolver = (item: any) => !!item[field] ? item[field] : defaultValue;
  return sortCustom(resolver, desc);
}

export const sortByEnum = (enumitems: Array<any>, itemResolver: any, desc: boolean) => {
  if (!itemResolver)
    itemResolver = (item: any) => item;
  let resolver = (item: any) => enumitems.indexOf(itemResolver(item))
  return sortCustom(resolver, desc)
}

export const sortByEnumField = (enumitems: Array<any>, field: any, desc: boolean) => {
  return sortByEnum(enumitems, (item: any) => item[field], desc)
}

export const sortCustom = (resolve: any, desc: boolean) => {
  return (a: any, b: any) => {
    if (desc) {
      let c = a;
      a = b;
      b = c;
    }

    if (resolve(a) > resolve(b))
        return 1;

    if (resolve(a) < resolve(b))
        return -1;

    return 0;
  };
}

export const ordinalSuffix = (i: number) => {
    let j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
  }


export const redirLogin = (withUrl: boolean = false) => {

  if (withUrl) {
    window.localStorage.loginRedirect = Router.asPath;
  }

  let redirectUri = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
  if (config.site.auth.callbackDomain != null) {
    redirectUri = config.site.auth.callbackDomain as unknown as string;  // why the fuck
  }

  if (config.site.auth.type === 'oauth2') {
    let oauthArgs = queryString.stringify({
      client_id: config.site.auth.clientId,
      redirect_uri: redirectUri + '/auth/oauth2',
      response_type: 'token',
      token_type: 'bearer',
      scope: config.site.auth.scopes
    });
    let oauthUrl = config.site.auth.url + '?' + oauthArgs;
    window.location.href = oauthUrl;
  }

}

export const useNetworkIssues = () => {
  const selector = useSelector(
    (state: any) => ({
      networkIssues: state.networkIssues,
    }),
    shallowEqual
  )

  return selector.networkIssues;
}


export const setUserOptions = (options: any) => {
  store?.dispatch({
    type: "USER_OPTIONS",
    state: options,
  })
}


export const useUserOptions = () => {
  const { didLoadUserOptions } = useSelector(
    (state: any) => ({
      didLoadUserOptions: state.didLoadUserOptions,
    }),
    shallowEqual
  )

  if (!didLoadUserOptions && typeof window !== 'undefined' && window.localStorage.userOptions) {
    setUserOptions(JSON.parse(window.localStorage.userOptions))
  }

  const { userOptions } = useSelector(
    (state: any) => ({
      userOptions: state.userOptions,
    }),
    shallowEqual
  )

  return userOptions;
}
