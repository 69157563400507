import * as React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import { createMedia } from "@artsy/fresnel";

export const mediaBreakpoints = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
};

const AppMedia = createMedia({
  breakpoints: mediaBreakpoints
});


export const useWidth = () => {
  const dispatch = useDispatch()
  const setWidth = (width: number) => 
    dispatch({
      type: 'DEVICE_WIDTH',
      deviceWidth: width,
    })

  React.useEffect(() => {
    if (selector.deviceWidth < 0) {
      window.onresize = () => setWidth(window.innerWidth);
      setWidth(window.innerWidth)
    }
  })

  const selector = useSelector(
    (state: any) => ({
      deviceWidth: state.deviceWidth,
    }),
    shallowEqual
  )

  return selector.deviceWidth
}

export interface Props extends React.ComponentProps<any> {
  as?: Element | React.Component | React.StatelessComponent | string,
  children?: any,
  windowWidth: number,
  minWidth?: number,
  maxWidth?: number,
}

export const Responsive: React.FunctionComponent<Props> = ({ as: asElement, windowWidth: width, minWidth, maxWidth, children: children, ...props }) => {
  if (width > 0) {
    if (!!props.style) {
      props.style = {}
    }

    if (width >= (minWidth || 0) && (!maxWidth || width < maxWidth)) {
      // Display on
    } else {
      return <></>;
    }
  }

  return React.createElement(asElement as any || "div", props, children)
}

export const { MediaContextProvider, Media } = AppMedia;
export const mediaStyles = AppMedia.createMediaStyle();
